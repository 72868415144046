import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { Project } from '../App';
import AOS from 'aos';
import ReactMarkdown from 'react-markdown';
import GithubIcon from '../images/logos/github-small.png';
import ReactGA from 'react-ga4';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import NotFound from './NotFound';
import remarkGfm from 'remark-gfm';

type Props = {
	fileContent: Project[] | undefined;
};

export default function ProjectDisplayer({ fileContent }: Props) {
	const [project, setProject] = useState<Project | null>(null);
	const [mediaToDisplay, setMediaToDisplay] = useState<JSX.Element[]>([]);

	useEffect(() => {
		if (!fileContent) return;

		const location: string = window.location.pathname;

		if (!fileContent) return;

		for (let i = 0; i < fileContent?.length; i++) {
			if (fileContent[i].path.toLocaleLowerCase() === location.toLocaleLowerCase()) {
				setProject(fileContent[i]);
				break;
			}
		}

		AOS.init();

		window.scrollTo(0, 0);
	}, [fileContent]);

	useEffect(() => {
		if (!project) return;

		const temp: string[] = project.media;
		const urlPrefix: string = 'https://ph4sm4-content.s3.eu-central-1.amazonaws.com';
		setMediaToDisplay(
			temp.map((filePath: string) => {
				if (filePath.endsWith('.mp4')) {
					return (
						<div className="mt-20">
							<video controls width="60%">
								<source src={urlPrefix + filePath} type="video/mp4" />
								Sorry, your browser doesn't support videos.
							</video>
						</div>
					);
				} else {
					return <img src={urlPrefix + filePath} className={`mt-20 ${project.fullWidth ? 'w-full' : 'w-3/5'}`} />;
				}
			})
		);
	}, [project]);

	useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname,
			title: window.location.pathname,
		});
	}, []);

	return project ? (
		<>
			<main className="mt-40 mb-20 w-full project--displayer">
				<div
					className="flex w-[80%] mx-auto p-8 flex-col project--content"
					data-aos="fade-down"
					data-aos-duration="1500"
					data-aos-delay="500"
					data-aos-once="true">
					<div className="project--title icons--holder">
						<ReactMarkdown>{project.title}</ReactMarkdown>
						{project.repoUrl && (
							<a href={project.repoUrl} target="_blank">
								<img src={GithubIcon} />
							</a>
						)}
					</div>
					<div className="project--description">
						<ReactMarkdown
							remarkPlugins={[remarkGfm]}
							children={project.description}
							components={{
								a: (props) => {
									return (
										<a href={props.href} target="_blank" rel="noopener noreferrer">
											{props.children}
										</a>
									);
								},
								code(props) {
									const { children, className, node, ...rest } = props;
									const match = /language-(\w+)/.exec(className || '');
									console.log(className);
									return match ? (
										<SyntaxHighlighter
											{...rest}
											language={match[1]}
											style={atomOneDark}
											customStyle={{
												backgroundColor: ' #202020',
												padding: '20px 30px',
												borderRadius: '20px',
											}}>
											{props.children.toString()}
										</SyntaxHighlighter>
									) : (
										<code {...rest} className={className}>
											{children}
										</code>
									);
								},
							}}></ReactMarkdown>
					</div>
					{mediaToDisplay}
				</div>
			</main>
			<Footer color="#080808" />
		</>
	) : (
		<main className="mt-60 mb-20 w-full">
			<NotFound />
		</main>
	);
}
