import { Project } from './App';

export const data: Project[] = [
	{
		title: '# A* algorithm',
		description: `
My own visual representation of A* pathfinding algorithm - created using Qt C++.
### Node explanation:
- Red - the shortest path from start to end
- Yellow with S - starting node
- Yellow with E - end node
- Black - a wall/obstacle
- Light blue - nodes that were visited by the algorithm
### Controlls:
- Start node can be changed using left click
- End node can be changed using right click
- Walls can be added by scroll-clicking on the node
`,
		media: ['/media/astar.mp4'],
		path: '/AStar-Visual-Representation',
		repoUrl: 'https://github.com/Ph4sm4/AStar',
		fullWidth: false,
	},
	{
		title: '# Calculator-Ip',
		description: `
Input an IP address as well as a subnet mask and receive calculated values.

### Calculations
- Net address
- Broadcast address
- Number of possible hosts
- First host address
- Last host address

### Settings
You can decide which input you would like to display or hide.

### My IP button
Get your local IP address.`,
		media: ['/media/calculator-ip.mp4'],
		path: '/Calculator-Ip',
		repoUrl: 'https://github.com/Ph4sm4/Calculator-Ip',
		fullWidth: false,
	},
	{
		title: '# Autoclicker',
		description: `Written for my own personal use. Design idea was taken from [opautoclicker](https://www.opautoclicker.com/) but slightly modified.

## Click options implemented:
### Mouse button
- Left
- Right
- Scroll

### Click type
- Single
- Double
- Triple
- Hold (holds a button pressed down)
- Hold + w (holds 'W' key and the specified button down) - useful in Minecraft strip mining
- Bridge (holds 'S', 'Shift' and specified button down) - useful in building long bridges in Minecraft

### Bindings
Start/Stop bindings are fully customizable. Starting/Stopping the autoclicker works even if the window is minimized.`,
		media: ['/media/autoclicker.png'],
		path: '/AutoClicker',
		repoUrl: 'https://github.com/Ph4sm4/AutoClicker',
		fullWidth: false,
	},
	{
		title: '# Weatheron',
		description: `
Weather app built using Qt C++.

### Complex weather data fetching from an API
Supports forecast for 2 weeks.

### Data
- Weatheron displays over 20 criteria of weather data. The background changes accordingly to the conditions at the given location.
- "My location" button exists, which finds your approximate location based on IP.
- Current conditions, as well as daily general conditions, are available.
- Both your local and location's current UTC times are displayed.`,
		media: ['/media/weatheron.png', '/media/weatheron.mp4'],
		path: '/Weatheron',
		repoUrl: 'https://github.com/Ph4sm4/Weatheron',
		fullWidth: false,
	},
	{
		title: '# Discord Bot',
		description: `
Discord bot was first created to make listening to music possible despite a huge wave of bans for music bots (late 2021). It was originally developed by me and one of my close friends, but after some time, I took the lead and proceeded with the work.

Since the beginning of 2022, I was one of the founders of Młodzieżowa Liga E-Sportowa (short. MLE - an e-sport league for Polish high schoolers) and therefore started developing various automation scripts, which enabled the growth of the project. I was fully responsible for management and further development of the system that was widely used by more than 1300 participants of our league (we also had around 2500 Discord members).

**Examples of usage were included at the bottom.**

### The software had many different use cases. Some of which were:
- Registration of the users with custom codes - proper role addition/removal, access to channels related to the category.
- Collection and complex analysis of personal statistics from every game played (used xlsx.js - a library that enabled working with Excel sheets).
- Webscraping tournament codes (needed to start a tournament game in League of Legends, as normal customs are not registered in Riot Games' API) from a system (called Toornament) that helped organize the ladder.
- Sending tournament codes via Discord, only to people who had the CAPTAIN role.
- Saving and later uploading match results (with selenium.js) to the system (Toornament).
- Creating custom display sheets with the statistics after every queue (every week on Monday morning).

_In order to avoid spamming, I also developed custom anti-spam systems._

### A general list of the commands specifically for the MLE league:
- **!clear_id** - Clears data of the user that has registered with a certain ID.
- **!current_queue_(lol/cs)** - Displays the current queue number for either LoL or CSGO.
- **!match_result_(csgo/lol/val)** - Uploads a match result between specified teams.
- **!register_mle** - Registration process with a given ID for the MLE server.
- **!score_(csgo/lol)** - Fetches data from a proper API, analyzes it, and uploads it to the appropriate Excel sheets.
- **!send_custom_codes** - Sends codes webscraped from the system (Toornament) to people with the CAPTAIN role via Discord.
- **!walkover_(csgo/lol)** - Uploads stats according to specified walkover conditions.
- **!win_(csgo/lol)** - Grants a specified team stats as if they won.

### A general list of the commands for music and other things:
- **!ban** - Bans the user from a Discord server.
- **!clear _X_** - Clears the last X messages from a channel (not older than 14 days).
- **!clearqueue** - Clears the song queue.
- **!forceskip** - Force-skips the currently playing song.
- **!join** - Makes the client join the user's channel.
- **!kick** - Kicks the user out of the server.
- **!leave** - Makes the client leave the channel.
- **!loop** - Loops the current song.
- **!stoploop** - Stops looping the song.
- **!lyrics** - Checks for and displays the lyrics of a given song, if found.
- **!play** - Plays a song from YouTube using a YouTube search or a YouTube link.
- **!queue** - Displays the current song queue.
- **!queueremove** - Removes a specific song from the queue.
- **!skip** - Skips the currently playing song.`,
		media: ['/media/registration.jpg', '/media/score_success.jpg', '/media/score_fail.jpg', '/media/stats.png', '/media/playing_music.png'],
		path: '/Discord-Bot',
		repoUrl: '',
		fullWidth: false,
	},
	{
		title: '# Exotic Island',
		description: `
At that time, I had already been learning Unreal Engine C++ programming for more than 4 months. During my learning, I developed several games with the help of my teacher from the [course](https://www.udemy.com/course/unrealcourse/) I enrolled in.

Apart from studying programming in Unreal Engine, I also wanted to get a grasp of map and level design, experiment with Mega Scans, and so on. That is why I followed one of the tutorials on YouTube and created the following island.  
The overall look was from the tutorial, but every single step and many adjustments were performed by myself alone.`,
		media: ['/media/island.jpg', '/media/island2.jpg'],
		path: '/Exotic-Island',
		repoUrl: '',
		fullWidth: true,
	},
	{
		title: '# Perlin Noise',
		description: `While learning Unreal Engine, I came across the term 'Perlin Noise' numerous times. I had already known that it is useful in terms of procedural texture and terrain generation.

And with that in mind, I decided to actually learn how this algorithm works. Understanding the 1D implementation alone was pretty challenging, but then came the second dimension and all the fun began. It was a real struggle for me to actually get a good grasp of how the 2D Perlin Noise works, but finally, I succeeded.

**A brief example of my application underneath.**`,
		media: ['/media/perlin-noise.mp4'],
		path: '/Perlin-Noise-Visual-Representation',
		repoUrl: 'https://github.com/Ph4sm4/PerlinNoise',
		fullWidth: false,
	},
	{
		title: '# Google Forms Copy',
		description: `The following project is not yet finished. However, I have successfully managed to recreate several significant features offered by the official Google Forms app. Everything was created by hand from the very beginning, and I did not use any external CSS libraries except the [Tailwind CSS](https://tailwindcss.com/) framework.

### Implemented features
- Full login system
- Full account creation system (with phone number verification)
- Complex 1:1 account profile system
- Custom country code box (flags and country names included just like in Google's account creation)

### Account updates
- Birthdate
- Name and surname
- Gender`,
		media: ['/media/google-forms-copy.mp4'],
		path: '/Google-Forms-Copy',
		repoUrl: 'https://github.com/Ph4sm4/GoogleFormsCopy',
		fullWidth: false,
	},
	{
		title: '# Ultimate Shooter',
		description: `This project was developed during the [course](https://www.udemy.com/course/unrealcourse/) I had enrolled in.

I decided to put it here considering it was the final project of that course and was a bit more complex. In addition, I successfully managed to create a fully functional ammo box/inventory system, which is easily extendable to any kind of ammunition, as well as built a few features on top of the UI system previously covered by the tutor.

### Things I have learned and used
- Line tracing
- Behaviour trees, BT Tasks, BT Services
- Animated UI, displaying widgets from C++
- Additive animations and skeletal attachment
- Nav Mesh Bounds Volume (this is how I even came across A*)
- Key press detection while overlapping with a Box Collision Component

*Obviously, lots of different things had to be covered, but these were mostly taught in the last part of the course, and so, during the creation of this project.*`,
		media: ['/media/shooter-game.mp4'],
		path: '/Ultimate-Shooter',
		repoUrl: '',
		fullWidth: false,
	},
	{
		title: '# Finelog App',
		description: `It is an Android app developed in Qt C++ and Firebase as a university entrance project. The app was created for a logistics company to be used by truck drivers. Its primary purpose is to enable drivers to send protocols regarding the state of given trucks after completing the shipment. Additionally, it provides authorized people with infrastructure to easily manage and view protocols, change data of certain inputs, and keep track of the users.

_The app itself is the most complicated personal project I have ever developed (mainly because I was using C++ and QtWidgets)_

### Implemented features:
- Registration (basic user data)
- Login (Firebase OAuth)
- Password reset
- User panel
- Admin panel

### User panel:
- Personal info management (e.g., name, email, and more)
- 2-factor authentication (user needs to confirm the email address to be able to send in protocols)
- User can inspect the protocols they have already sent in
- Specific form inputs have their fields open for modification inside the Admin Panel

### Admin Panel:
- A list of all the users with the ability to view their personal info and protocols, as well as inspect them and view their data. Unread protocols (and users with unread protocols) are marked with a green border. Various sorting options are also available.
- User management (ability to delete a user's account)
- Form data input management (in accordance with the guidelines provided by the company)

### Obstacles:
During the development process, I had to deal with hundreds of issues, bugs, and errors. The initial setup of the environment took me almost 10 hours due to a faulty Qt installation. Furthermore, I decided to use the Firebase REST API, which at the beginning was the easiest to work with (as Firebase's SDK implementation for C++ is overly complicated). This choice made me create an enormous DatabaseHandler class with over 20 'endpoints'.

The biggest issue I had to face was inside the Admin Panel -> User account deletion. Firebase's Admin SDK has no REST endpoints and is not implemented for C++. Therefore, I had to write and run a separate Express.js server (exposed with Ngrok) to which I would send POST requests and perform user deletion using the Admin SDK for Node.`,
		media: ['/media/finelogapp_full.mp4'],
		path: '/Finelog-App',
		repoUrl: 'https://github.com/Ph4sm4/FinelogApp',
		fullWidth: false,
	},
	{
		title: "# Hey, that's my fish",
		description: `It is a project done for my tutorial classes at the university. It was my first-ever encounter with C, but I still managed to complete this project in under 20 hours.

Project-Penguins is a recreation of a board game known as "Hey, that's my fish". The rules can be found at the bottom of the page. The game logic is fairly easy to grasp, but I figured that it would be a good idea to make things a bit more interesting. Therefore, I was thinking of a nice and inventive way of generating the grid for our game. As in the rules, the grid can be M x N, where M and N are the rows and columns of tiles containing 0, 1, 2, or 3 fishes. I found Perlin Noise to be somewhat interesting to implement.

### Rendering the grid:
- Perlin Noise 2D implemented.
- Noise is being generated for a 256 x 256 grid.
- If somehow the grid needs to be bigger than that, the code inside /GameGrid/Grid.c -> void constructGrid(struct GameGrid* obj) needs changing.`,
		media: ['/media/project-penguins.mp4', '/media/project-penguins-rules.PNG'],
		path: '/Project-Penguins',
		repoUrl: 'https://github.com/Ph4sm4/Project-Penguins',
		fullWidth: false,
	},
	{
		title: '# Crypto Transactions Visualizer',
		description: `It is an easily usable crypto-transactions graphing tool written in vanilla JavaScript. A showcase of the visualizer is available at the bottom of the page.

### _'transactionValue'_ property:
- This field controls the color and thickness of the lines.
- Colors of the lines and the nodes are easily changeable in _TransactionRenderer.ts_.
- Determining how fast the lines get thick is also customizable in _TransactionRenderer.ts_.

### Functionalities:
- Moving the _wallet_ and _incoming/outgoing_ nodes by click-dragging. All linking lines are going to be updated accordingly.
- Moving the entire graph/canvas by click-dragging somewhere else than on a node.

### In-code usage:
~~~tsx
export default function TransactionGraph({}: Props) {
  const [windowAvailable, setWindowAvailable] = useState<boolean>(false); // to access the 'window' object

  useEffect(() => {
    setWindowAvailable(true);
  }, []);

  return (
    windowAvailable && (
      <main>
        <TransactionVisualizer
          width={window.innerWidth}
          height={window.innerHeight - 250}
          data={data}
        />
      </main>
    )
  );
}
~~~`,
		media: ['/media/Crypto-Transactions-Visualizer.mp4'],
		path: '/Crypto-Transactions-Visualizer',
		repoUrl: 'https://github.com/Ph4sm4/Crypto-Transactions-Visualizer',
		fullWidth: false,
	},
	{
		title: '# SHA256 algorithm',
		description: `
Implemented completely from scratch and by myself, thus the solution is longer than some I have seen on the internet. But it works perfectly as it should.

I created a simple class:

~~~cpp
class SHA256
{
public:
    string hash(const string &msg);

private:
    void prepareBlocks(const string &str);
    void blockPadding(bitset<SHA256_BLOCK_SIZE> &block);
    void processBlocks();
    void processBlock(const bitset<SHA256_BLOCK_SIZE> &block);

    void prepareWORDChunks(WORD *chunks, const bitset<SHA256_BLOCK_SIZE> &block);
    void prepareMsgSchedule(WORD *msgSchedules, const WORD *chunks);

    void computeHash(WORD *chunks, WORD *msgSchedules);

    WORD ROTR(WORD x, int n) const;
    WORD CH(WORD e, WORD f, WORD g) const;
    WORD MAJ(WORD a, WORD b, WORD c) const;

    string createOutputHash() const;

    vector<bitset<SHA256_BLOCK_SIZE>> blocks;
    bitset<SHA256_BIT_SIZE>
        hashBits;

    void printMessageBlock() const;

    // initial hash values (first 32 bits of the fractional parts of the square roots of the first 8 primes 2..19):
    WORD hVals[8] = {
        0x6a09e667,
        0xbb67ae85,
        0x3c6ef372,
        0xa54ff53a,
        0x510e527f,
        0x9b05688c,
        0x1f83d9ab,
        0x5be0cd19};

    // constants (first 32 bits of the fractional parts of the cube roots of the first 64 primes 2..311):
    WORD k[64] = {
        0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
        0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
        0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
        0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
        0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
        0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
        0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
        0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2};

    int strBitSize;
};
~~~

which does exactly what you would expect - hashes the provided string with SHA256 algorithm.

### Example outputs

~~~text
input string: abc
SHA256: ba7816bf8f01cfea414140de5dae2223b00361a396177a9cb410ff61f20015ad

input string: I'm now testing a very long message that will create a 1024 bit message block
SHA256: ad90ea1eff8fa44f8a8fa54bd0860bca7813eeea7838901e0884a9424f054b4b

input string: Olaf Dalach - Ph4sm4Solutions 12.23.2024
SHA256: a3b391c60e2f72afbaa7a6ecba464f2b2ce756d52dfd4b43ad7c7664c21713bb
~~~
`,
		media: [],
		path: '/SHA-256',
		repoUrl: 'https://github.com/Ph4sm4/SHA256',
		fullWidth: false,
	},
];
