import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigation } from 'react-router-dom';
import Header from './components/Header';
import MainPage from './components/MainPage';
import ProjectDisplayer from './components/ProjectDisplayer';
import ReactGA from 'react-ga4';
import NotFound from './components/NotFound';
import { data } from './ProjectsDescription';

const TRACKING_ID1 = 'G-L5RKPQR0H9';

ReactGA.initialize(TRACKING_ID1);

export type Project = {
	title: string;
	description: string;
	media: string[];
	path: string;
	repoUrl: string;
	fullWidth: boolean;
};

function App() {
	const [fileContent, setFileContent] = useState<Project[]>();
	useEffect(() => {
		setFileContent(data);

		console.log('data fetched');
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname,
			title: 'Main page',
		});
	}, []);

	return (
		<Router>
			<Header />
			<Routes>
				<Route path="/" element={<MainPage />} />
				<Route path="/*" element={<ProjectDisplayer fileContent={fileContent} />} />
			</Routes>
		</Router>
	);
}

export default App;
